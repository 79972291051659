@use "variables" as *;
@use '@angular/material' as mat;

@include mat.core();

$cep-typography-tabs: mat.define-typography-level(
  $font-family: $font,
  $font-weight: 500,
  $font-size: $font-size-12,
  $line-height: $font-size-12,
  $letter-spacing: normal
);

$cep-typography-dialog: mat.define-typography-level(
  $font-family: $font,
  $font-weight: 400,
  $font-size: $font-size-14,
  $line-height: $font-size-20,
  $letter-spacing: normal
);

$cep-typography-dialog-title: mat.define-typography-level(
  $font-family: $font,
  $font-weight: 500,
  $font-size: $font-size-24,
  $line-height: $font-size-16,
  $letter-spacing: normal
);

$cep-typography: mat.define-typography-config(
  $font-family: $font,
);

$cep-palette-primary: (
  50 : #f1eaff,
  100 : #dccbfe,
  200 : #c4a8fe,
  300 : #ac85fd,
  400 : #9b6afc,
  500 : #8950fc,
  600 : #8149fc,
  700 : #7640fb,
  800 : #6c37fb,
  900 : #FF00FF,
  A100 : #FF0000,
  A200 : #ffffff,
  A400 : #dacfff,
  A700 : #c6b6ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

$cep-palette-secondary: (
  50 : #ffe4eb,
  100 : #febacc,
  200 : #fd8daa,
  300 : #fb5d89,
  400 : #f8376f,
  500 : #F50057,
  600 : #e40054,
  700 : #cf0051,
  800 : #bb004e,
  900 : #97004a,
  A100 : #ffffff,
  A200 : #ffe1e5,
  A400 : #ffaeb8,
  A700 : #ff95a2,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$cep-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($cep-palette-primary, 500),
    accent: mat.define-palette($cep-palette-secondary, 500, 200, 900, A100)
  ),
  typography: $cep-typography,
  density: -3
));


@include mat.core-theme($cep-theme);
@include mat.menu-theme($cep-theme);
//@include mat.tabs-theme($cep-theme);

@include mat.tabs-typography(mat.define-typography-config(
  $button: $cep-typography-tabs,
));
@include mat.tabs-color($cep-theme);
@include mat.tabs-density($cep-theme);

@include mat.checkbox-theme($cep-theme);

@include mat.dialog-color($cep-theme);
@include mat.dialog-density($cep-theme);
@include mat.dialog-typography(mat.define-typography-config(
  $headline-1: $cep-typography-dialog-title,
  $headline-2: $cep-typography-dialog-title,
  $headline-3: $cep-typography-dialog-title,
  $headline-4: $cep-typography-dialog-title,
  $headline-5: $cep-typography-dialog-title,
  $headline-6: $cep-typography-dialog-title,
  $subtitle-1: $cep-typography-dialog-title,
  $subtitle-2: $cep-typography-dialog-title,
  $body-1: $cep-typography-dialog,
  $body-2: $cep-typography-dialog,
  $caption: $cep-typography-dialog,
));

@include mat.datepicker-theme($cep-theme);
@include mat.input-theme($cep-theme);
@include mat.form-field-theme($cep-theme);

@include mat.radio-theme($cep-theme);
@include mat.slide-toggle-theme($cep-theme);




.mat-small {
  @include mat.datepicker-density(-5);
  @include mat.form-field-density(-5);
}



/****
Individual styles customizations
 */
.tab-group {
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: $color-title !important;
  }

  --mat-tab-header-divider-height: 0;

}


/****
** Checkboxes
****/
.mat-mdc-checkbox {
  font-family: Montserrat, "Helvetica Neue", sans-serif;

  &.small {
    --mdc-checkbox-state-layer-size: 20px;
    .mdc-checkbox, .mdc-checkbox__background {
      width: 12px;
      height: 12px;
    }

    .mdc-checkbox .mdc-checkbox__background {
      top: calc((var(--mdc-checkbox-state-layer-size) - 12px) / 2);
      left: calc((var(--mdc-checkbox-state-layer-size) - 12px) / 2);
    }

    .mdc-checkbox {
      padding: calc((var(--mdc-checkbox-state-layer-size) - 12px) / 2);
      margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2);
      flex: 0 0 12px;
    }
  }
}

/****
** Datepicker
****/
.data-picker-field {
  --mdc-filled-text-field-container-color: transparent;
  /*--mat-form-field-container-text-size: $font-size-16;*/

  .mdc-form-field {}

  .mat-mdc-form-field-bottom-align::before {
    height: $m1;
  }


}

