/* CIH Styles */
@use './styles/theme';
@use './styles/global';
@use './styles/mixins';
@use './styles/sanitize';
@use './styles/variables';
@use './styles/typography';
@use './styles/layout';
@use './styles/elements';
/*@use './styles/material';*/
@use './styles/tables';
@use './styles/forms';
@use './styles/notifications';
@use './styles/modals';

/* CSS from libraries */
@use './styles/ng-select';
@use '@ngneat/hot-toast/src/styles/styles.scss';

//Fonts
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");



