@use "variables" as *;

/****************** Hot toast overrides ****************/
:root {

  --hot-toast-max-width: 550px;
  --hot-toast-close-btn-background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3e%3c/svg%3e");
  --hot-toast-close-btn-background-size: 1.0rem !important;
  --hot-toast-close-btn-opacity: 1;
  --hot-toast-close-btn-margin-top: 0.4em;
}

.hot-toast-close-btn {
  font-family: $font;
  font-size: $font-size-14;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    opacity: 0.5 !important;
  }
}



