@use "variables" as *;


h1, .title-text {
  font-size: $font-size-24;
  font-weight: 500;
  line-height: 2.5rem;
  letter-spacing: 0;
  color: $color-title;
  margin-top: $m4;
}

h2, .subtitle-text {
  font-size: $font-size-14;
  font-weight: 600;
  line-height: 1.5rem;
  color: $color-subtitle;
}

section {
  h2 {
    font-weight: 500;
    font-size: $font-size-18;
    line-height: $line-height-20;
    color: $color-subtitle;
  }

}

h3, .highlighted-text {
  font-size: $font-size-18;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
  color: $color-title;
  margin-top: $m9;
}

h4 {
  font-size: $font-size-12;
  font-weight: 400;
  line-height: $line-height-16;
  color: $color-title;
}

p, .text {
  font-size: $font-size-16;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: 0.006rem;
  margin: $m4 0;

  white-space: break-spaces;

  a {
    color: $color-primary;

    &:visited {
      color: $color-primary;
    }

    &:hover {
      color: $color-secondary;
    }
  }
}

.small-text {
  font-size: $font-size-12;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.012rem;
}

.smaller-text {
  font-size: $font-size-12;
  font-weight: normal;
  line-height: $line-height-14;
  letter-spacing: 0.012rem;
}

.x-small-text {
  font-size: $font-size-10;
  font-weight: normal;
  line-height: 1.25rem;
  letter-spacing: 0.012rem;
}


.text-center {
  text-align: center;
}

b, strong {
  font-weight: 600;
}

.indent {
  margin-left: $m3;
}


.no-padding {
  padding: 0;
}

.secondary-text {
  color: $color-secondary-text;
}

.tertiary-text {
  color: $color-tertiary-text;
}
