@use "variables" as *;
@use "mixins" as *;

.button {
  cursor: pointer;
  -webkit-appearance: none;
}

a {
  text-decoration: none;
}

.box {
  background-color: $color-background-white;
  box-shadow: 0px 4px 8px rgba(4, 15, 37, 0.08);
  border-radius: 8px;
  padding: $m1;
}

.page {
  padding: $m4 $m5;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bordered-box {
  padding: $m4;
  border: 1px solid $color-white-dark;
  border-radius: 10px;
  background-color: $color-background-white;
}

/****
* Keyword highlighting
***/
span.hit {
  color: $color-hit;
  font-weight: 500;
}

/*****
* Data table styles
*****/

.cell-align-right {
  justify-content: flex-end;
}

.header-cell-align-right {
  .datatable-header-cell-template-wrap {
    justify-content: flex-end;
  }
}

.cell-align-center {
  justify-content: center;
}

.cell-full-width {
  .datatable-body-cell-label {
    width: 100%;
  }
}

.header-cell-align-center {
  .datatable-header-cell-template-wrap {
    justify-content: center;
  }
}

.header-cell-align-bottom {

  .datatable-header-cell-template-wrap {
    align-items: flex-end !important;
  }
}

.header-cell-clear {
  background-color: transparent !important;
  overflow:visible !important;
}



.cell-level {
  padding: 0 !important;

  .datatable-body-cell-label {
    height: 100%;
  }
}

.term-clauses {
  span {
    color: $color-secondary-text;
  }
}


/******
* Tabs
******/

.tab-group.mat-tab-group {
  font-family: $font !important;

  .mat-tab-header {
    border-bottom: 0;
    padding-bottom: $m3;
  }
}


.tab-group.mat-tab-group.mat-primary .mat-ink-bar, .tab-group.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $color-primary;
}

.tab-label {

  &.mat-tab-label {
    height: auto;
    padding: $m1 $m2;
    opacity: 1;
    min-width: auto;
  }

  &.mat-tab-label-active {
    font-weight: 600;
  }

  .mat-tab-label-content {
    font-family: $font;
    font-size: $font-size-13;
    color: $color-title;
  }
}

.tab-label-medium {

  &.mat-tab-label {
    height: auto;
    padding: $m1 $m2;
    opacity: 1;
    min-width: auto;

    font-weight: 300;
  }

  &.mat-tab-label-active {
    font-weight: 500;
  }

  .mat-tab-label-content {
    font-family: $font;
    font-size: $font-size-12;
    color: $color-title;
    line-height: $line-height-14;
  }
}

.tab-label-small {

  &.mat-tab-label {
    height: auto;
    padding: $m0 $m1;
    opacity: 1;
    min-width: auto;
  }

  &.mat-tab-label-active {
    font-weight: 500;
  }

  .mat-tab-label-content {
    font-family: $font;
    font-size: $font-size-10;
    line-height: $line-height-14;
    color: $color-title;
  }
}


/****
* Buttons
****/

button {

  @include flexbox($wrap: nowrap, $align: center);
  border: 0;
  cursor: pointer;
  text-decoration: none;


  &.full {
    padding: $m1 $m4;
    font-family: $font;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    font-size: $font-size-14;
    line-height: 1.5rem;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: $m1;

    transition: background-color 0.2s ease-out;


    &.full-width {
      width: 100%;
      justify-content: center;
    }

    &.small {
      font-size: $font-size-11;
      line-height: 0.875rem;
      padding: $m1;
    }


    &.primary {
      background-color: $color-primary;
      color: $color-white;

      &.border {
        border: 2px solid $color-primary;
      }

      &:hover {
        background-color: $color-primary-darker;

        &.border {
          border: 2px solid $color-primary-darker;
        }
      }

      &:disabled {
        color: $color-white-disabled;
        box-shadow: none;
        background-color: $color-primary-disabled;

        &.border {
          border: 2px solid $color-primary-disabled;
        }

        &:hover {
          background-color: $color-primary-disabled;
        }
      }


    }

    &.secondary {
      background-color: $color-secondary;
      color: $color-white;

       &.border {
        border: 2px solid $color-secondary;
      }

      &:hover {
        background-color: $color-secondary-darker;
      }

      &:disabled {
        color: $color-white-disabled;
        box-shadow: none;
        background-color: $color-secondary-disabled;

        &:hover {
          background-color: $color-secondary-disabled;
        }
      }
    }


    &.gray {
      background-color: $color-light-gray;
      color: $color-white;

      &:hover {
        background-color: $color-dark-gray;
      }

    }

    &:disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);

      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }


  }

  &.outline {
    padding: $m1 $m4;
    font-family: $font;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    font-size: $font-size-14;
    line-height: 1.5rem;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: $m1;
    background-color: transparent;
    border: 2px solid currentColor;

    transition: background-color 0.2s ease-out;

    &.full-width {
      width: 100%;
      justify-content: center;
    }

    &.small {
      font-size: $font-size-11;
      line-height: 0.875rem;
      padding: $m1;
    }

    &.primary {
      color: $color-primary;

      &:hover {
        background-color: $color-primary-darker;
        border-color: $color-primary-darker;
        color: $color-white;
      }

      &:disabled {
        color: $color-primary-disabled;
        box-shadow: none;
        background-color: transparent;
        border-color: $color-primary-disabled;

        &:hover {
          background-color: transparent;
          border-color: $color-primary-disabled;
          color: $color-primary-disabled;
        }
      }
    }

    &.secondary {
      color: $color-secondary;

      &:hover {
        background-color: $color-secondary-darker;
        color: $color-white;
        border-color: $color-secondary-darker;
      }

      &:disabled {
        color: $color-secondary-disabled;
        box-shadow: none;
        background-color: transparent;
        border-color: $color-secondary-disabled;

        &:hover {
          background-color: $color-secondary-disabled;
        }
      }
    }

    &.gray {

      color: $color-medium-gray;
      background-color: transparent;

      &:hover {
        background-color: $color-dark-gray;
        color: $color-white;
        border-color: $color-dark-gray;
      }

       &:disabled {
        color: $color-light-gray;
        box-shadow: none;
        background-color: transparent;
        border-color: $color-light-gray;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);

      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  &.round {
    border-radius: 100%;
    background-color: transparent;
    color: $color-light-gray;
    transition: background-color 0.2s ease-out;
    width: 2.25rem;
    height: 2.25rem;

    &.small {
      width: 1.875rem;
      height: 1.875rem;

      .mat-icon {
        width: 1.125rem;
        height: 1.125rem;
      }

      .material-icons {
        font-size: $font-size-18;
      }
    }

    &.x-small {

      width: 1.5rem;
      height: 1.5rem;

      .mat-icon {
        width: 0.875rem;
        height: 0.875rem;
        vertical-align: text-bottom;
      }

      .material-icons {
        font-size: $font-size-14;

      }
    }

    &.dark {
      color: $color-dark-gray;
    }

    &.primary {
      color: $color-primary;
    }

    &.primary-hover {
      &:hover {
        color: $color-primary;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    &:disabled {
      color: $color-light-gray;
      background-color: transparent;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.text-button {

    font-size: $font-size-12;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }

    &.primary {
      color: $color-primary;
      background-color: transparent;


      &:disabled {
        color: $color-primary-disabled;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.gray {
      color: $color-secondary-text;
      background-color: transparent;

      &:hover {
          text-decoration: none;
          color: $color-primary;
        }

      &:disabled {
        color: $color-primary-disabled;
      }
    }

    &.light-gray {
      color: $color-tertiary-text;
      background-color: transparent;

      &:hover {
          text-decoration: none;
          color: $color-primary;
        }

      &:disabled {
        color: $color-primary-disabled;
      }
    }

  }

  &.icon-button {
    transition: color 0.2s ease-out;
    background-color: transparent;


    &:hover {
      color: $color-secondary;
    }

    &.dark {
      color: $color-dark-gray;
    }

    &.light {
      color: $color-gray;
    }

    &.primary {
      color: $color-primary;
    }

    &.gray {
      color: $color-active-gray;

      &:hover {
        color: $color-primary;
      }
    }


    &:disabled {
      color: $color-light-gray;
      background-color: transparent;
    }

  }

  &.medium {
    font-size: $font-size-13;
    line-height: $line-height-14;
  }
}


/****
* UI elements
****/
.dot {
  width: $m1;
  height: $m1;
  color: inherit;
  background-color: currentColor;
  border-radius: $m1;
  margin-left: $m1;
  display: inline-block;
  vertical-align: middle;
}


/*****
* Overlay styles
*****/
.coming-soon {

  position: relative;

  * {
    filter: blur(0.8px) opacity(0.8);
  }

  &:after {
    content: 'coming soon';
    position: absolute;
    padding: $m1 $m4;
    border-radius: 9999px;
    border: 2px solid $color-primary;
    background-color: #FFFFFFBB;
    display: block;
    color: $color-primary;
    font-size: $font-size-14;
    font-weight: 500;
    z-index: 20;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.text-right {

    * {
      filter: none;
    }

    &:after {
      content: 'coming soon';
      position: absolute;
      white-space: nowrap;
      padding: $m0 $m2;
      border-radius: 9999px;
      border: 1px solid $color-primary;
      background-color: #FFFFFFBB;
      display: block;
      color: $color-primary;
      font-size: $font-size-10;
      font-weight: 500;
      z-index: 20;
      left: 50%;
      top: 38%;
      transform: translate(110%, -50%);
    }


  }

}
