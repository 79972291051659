// Flexbox mixin
@mixin flexbox($wrap: wrap, $direction: row, $justify: space-between, $align: center) {
  display: flex;
  flex-wrap: $wrap;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin inline-flexbox($wrap: wrap, $direction: row, $justify: space-between, $align: center) {
  display: inline-flex;
  flex-wrap: $wrap;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

// Grid mixin
@mixin grid($columns, $rows: auto) {
  display: grid;
  grid-template-columns: $columns;
  grid-template-rows: $rows;
}

@mixin grid-item($column-start, $column-end, $row-start, $row-end) {
  grid-column-start: $column-start;
  grid-column-end: $column-end;
  grid-row-start: $row-start;
  grid-row-end: $row-end;
}

// Font mixin
@mixin title {
  font-family: $font;
  font-weight: 500;
}

//ng select
@mixin rtl {
  @at-root [dir="rtl"] #{&} {
    @content
  }
}
