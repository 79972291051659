@use "variables" as *;
@use "mixins" as *;

.modal {

  padding: 0;

  .modal-title {
    @include flexbox($justify: space-between);
    text-align: left;
    width: 100%;
    color: $color-modal-title;
  }

  .modal-body {
    @include flexbox($direction: column, $align: flex-start);
    width: 100%;
    padding-top: $m3;
  }

  .modal-footer {
    @include flexbox($justify: center);
    margin-top: $m4;
  }


  .modal-actions {
    @include flexbox($justify: center, $align: center);

    button {
      margin-right: $m2;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
    width: 100%;
    padding: 0 24px 20px 24px !important;
    margin: 0;
  }

.cdk-overlay-dark-backdrop {
  background: rgba(16, 19, 24, 0.12);
}

.modal-container {

  width: 90%;
  max-width: 696px;
  font-family: $font;


  .mat-mdc-dialog-title {
    margin: 0;
    font: unset;
    font-size: $font-size-18;
    font-weight: 500;
    line-height: $font-size-18;
    color: $color-modal-title;
    letter-spacing: 0.006rem;
  }

  .mat-mdc-dialog-container {
    padding: $m3;
    box-shadow: 0 4px 8px rgba(4, 15, 37, 0.08);
    border-radius: $border-radius-modal;
    color: $color-modal-text;
  }



  .modal-actions {
    .mat-mdc-dialog-actions {
      padding: 8px 0 0 0;
      display: flex;
      flex-wrap: nowrap;
      min-height: unset;
      align-items: center;
      box-sizing: content-box;
      margin-bottom: 0;
    }
  }
}




