@use "variables" as *;

html {
  /**
  * using the 62.5% trick would have implications in MFE that were develop without this in mind. So it's best not to
  * change the root element font-size.
  */

  /*font-size: 62.5%; /* base font-size is 16px, using 62.5% sets the base to 10px */
  font-size: 100%;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-family: $font;
  font-weight: normal;
  font-size: $font-size-12;
  line-height: 1.5;
  color: $color-text;
  background-color: $color-background;
  margin: 0;
  letter-spacing: 0.006rem;
}

html, body {
  height: 100%;
  height: -webkit-fill-available;
}

