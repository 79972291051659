
$font: 'Montserrat', sans-serif;
$font-size-8: 0.5rem; // 8px
$font-size-9: 0.5625rem; // 9px
$font-size-10: 0.625rem; // 10px
$font-size-11: 0.688rem; // 11px
$font-size-12: 0.75rem; // 12px
$font-size-13: 0.813rem; // 13px
$font-size-14: 0.875rem; // 14px
$font-size-16: 1.00rem; // 16px
$font-size-18: 1.125rem; // 18px
$font-size-20: 1.25rem; // 20px
$font-size-24: 1.50rem; // 24px
$font-size-30: 1.875rem; // 30px
$font-size-32: 2rem; // 32px

$line-height-10: 0.625rem; //10px
$line-height-12: 0.75rem; //12px
$line-height-14: 0.875rem; //14px
$line-height-16: 1rem; //16px
$line-height-18: 1.125rem; // 18px
$line-height-20: 1.25rem; //20px
$line-height-24: 1.50rem; //24px

// Colors
$color-gray: #DADADA;
$color-dark-gray: #6A6A6A;
$color-active-gray: #808080;

$color-medium-gray: #A3A3A3;
$color-light-medium-gray: #C4C4C4;
$color-light-gray: #D4D4D4; //#C4C4C4;
$color-green: #60A799;
$color-pale-green: #dfedeb;
$color-light-green: #D7F9EF;
$color-white: #FFFFFF;
$color-white-dark: #F1F1F1;
$color-red: #FF1203;
$color-dark-blue: #2F2783;

$color-very-light-gray: #FBFBFB;

$color-area-title: $color-dark-gray;
$color-subtitle: $color-dark-gray;
$color-border-gray: $color-active-gray;

$color-code: $color-white;
$color-hit: $color-red;
$color-code-background: #3A3A3A;

$color-tertiary-text: $color-medium-gray;
$color-secondary-text: $color-dark-gray;

$color-severity-high: $color-red;
$color-severity-medium: #FF9A03;
$color-severity-low: #F1C40F;
$color-severity-not-relevant: $color-light-medium-gray;

$form-border: 1px solid $color-border-gray;
$form-separator: 1px solid $color-white-dark;
$box-border: 1px solid $color-white-dark;
$section-border: 1px solid $color-light-gray;

$color-primary: #8950FC;
$color-primary-darker: #5F38B0;
$color-primary-lighter: #A073FC;
$color-primary-light: #e7dcfe;
$color-primary-background: #F9F6FF;

$color-secondary: #f50057;
$color-secondary-darker: darken(#f50057, 20);
$color-secondary-disabled: lighten(#f50057, 30);

$color-title: #000000;
$color-text: #212121;
$color-text-disabled: #BDBDBD;
$color-background: #FFFFFF;  /* #fafafa; */
$color-background-white: $color-white;
$color-background-disabled: #E0E0E0;



$color-white-disabled: rgba(255, 255, 255, 0.5);
$color-primary-disabled: rgb(216, 204, 248);


$color-hover: $color-primary;
$color-form-button: $color-primary;
$color-form-focus: $color-primary;
$color-form-text: $color-text;
$color-form-text-separator: $color-tertiary-text;

$color-form-border: #DCD9D9;
$color-form-placeholder: $color-light-gray;
$color-disabled: $color-light-gray;

$color-button-text: #FFFFFF;
$color-button-red: #FC817A;


$color-cta: $color-primary;


$color-system-success: #58B458;
$color-system-warning: #FEE13D;
$color-system-error: #FF1203;

$color-system-info: #2F96B4;

$color-modal-title: $color-dark-gray;
$color-modal-text: $color-text;
$color-modal-button: #666666;



//Interface
$height-header: 5rem; //4.5rem;
$side-bar-width: 18rem;
$side-bar-closed-width: 1.25rem;
$contextual-bar-width: 16.875rem;
$contextual-bar-closed-width: 0rem;
$contextual-bar-close-button: 3.5rem;

// shadows
$shadow-down-xs: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
$shadow-down-xl: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
$shadow-right: 2px 0px 4px rgba(0, 0, 0, 0.25);
$shadow-alert-card:  0 2px 2px rgba(0, 0, 0, 0.05);


// Margins
$m0: 0.125rem;
$m1: 0.25rem;
$m2: 0.5rem;
$m3: 0.75rem;
$m4: 1.0rem;
$m5: 1.25rem;
$m6: 1.5rem;
$m7: 1.75rem;
$m8: 2.0rem;
$m9: 2.25rem;
$m10: 2.5rem;
$m11: 2.75rem;
$m12: 3.0rem;
$m13: 3.25rem;
$m14: 3.50rem;
$m15: 3.75rem;

@for $i from 1 through 10 {
  .mb-#{$i} {
    margin-bottom: $m1 * $i;
  }
}

$pageMargin: 1.875rem;

// Breakpoints
$xs: 350px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$breakpoints: (xs: 350px, sm: 576px, md: 768px, lg: 992px, xl: 1200px);


// Border-radius
$border-radius-small: 0.25rem;
$border-radius: 0.75rem;
$border-radius-bar: 0.625rem;
$border-radius-modal: 0.5rem;


