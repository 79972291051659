@use "variables" as *;

form.form-group {
  width: 100%;

  label {
    display: block;
    font-size: $font-size-13;
  }

  input, select {
    display: block;
    font-family: $font;
    width: 100%;
    padding: 0.5rem 0;
    margin-bottom: $m2;
    color: $color-form-text;
    vertical-align: middle;
    border: 0;
    border-bottom: $form-border;

    &:-moz-placeholder {
      color: $color-form-placeholder;
    }

    &::-moz-placeholder {
      color: $color-form-placeholder;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $color-form-placeholder;
    }

    &::-webkit-input-placeholder {
      color: $color-form-placeholder;
    }

    &:focus {
      border-color: $color-form-focus;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.16);
      transition: box-shadow 0.2s ease-in-out;
      outline: 0;

    }

    &[disabled],
    &[readonly] {
      cursor: not-allowed;
      color: $color-disabled;

      &:focus {
        border-bottom: $form-border;
        box-shadow: none;
      }
    }
  }

  fieldset[disabled] .form-textfield {
    cursor: not-allowed;
    background-color: $color-disabled;
  }

  .form-button {
    display: inline-block;
    padding: 12px 70px;
    background-color: $color-form-button;
    color: $color-white;
    border: 0;
    text-decoration: none;
    cursor: pointer;
    border-radius: 99999px;

    font-size: $font-size-14;
    font-weight: bold;
    line-height: 1.75rem;
    text-transform: uppercase;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: darken($color-form-button, 20);
      transition: background-color 0.2s ease-in-out;
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: $color-disabled;
    }
  }

  .form-error {
    color: $color-system-error;
    border-color: $color-system-error;
    transition: all 0.2s ease-in-out;
  }

  .form-text {
    font-size: $font-size-12;
    color: $color-form-text-separator;
    margin: $m1 0;

    &.block {
      margin: $m3 0;
    }

    h4 {
      font-size: $font-size-12;
      color: $color-form-text;
      margin-top: $m5;
      margin-bottom: 0;

    }

  }

  .form-example {
    color: $color-secondary-text;
    font-size: $font-size-10;
    margin-top: -$m4;
    margin-bottom: $m4;
  }

}
